<template>
    <div class="loading-programs" >
        <div class="video-load" v-if="videoLoad">
            <div class="video">
                <video  x5-playsinline="true" playsinline="true" webkit-playsinline="true" x-webkit-airplay="true" x5-video-player-type="h5-page" x5-video-orientation="portraint"  :src="videoSrc" autoplay muted loop></video>
            </div>
            <div class="loading-circle" v-if="!enter">
                <div id="preloader">
                    <div id="loader">
                    </div>
                </div>
            </div>
            <div class="loading-jump-to" v-if="enter" @click="enterExhibition">
                <div>
                    <img src="/jump.png">
                </div>
            </div>
        </div>
        <div class="choose-type" v-if="!videoLoad">
            <div>

                <div>
                    <div class="btn-shine" @click="chooseVisitType(1)">
                        <img src="zdmy.png">
                    </div>
                    <div class="btn-shine" @click="chooseVisitType(2)">
                        <img src="sdmy.png">
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import { getUserAgentInfo } from "@/js/tool";
export default {
    name: 'loadingProgram',
    props:{
        tem_id:Number
    },
    data(){
        return{
            loading:true,
            enter:false,
            videoSrc: '',
            videoLoad: true

        }
    },
    created(){
        if(this.tem_id == 44){
            this.videoSrc = '/美尔奇品牌视频.mp4';
        }else if(this.tem_id == 43){
            this.videoSrc = '/template/43/video/xiehe.mp4';
        }else{
            this.videoSrc = '/template/43/video/1.mp4';
        }
    },
    methods:{
        readyEnter(){
            this.enter = true;
        },
        enterExhibition(){
            this.$bus.$emit("ring",true)
            this.videoLoad = false;
            let versions = getUserAgentInfo()
            if(!versions.ios){
                switchFullScreen();
            }
            if(this.tem_id != 44){
                this.$emit('changeLoadingStatus');
            }
        },
        chooseVisitType(type){
            this.$emit('changeLoadingStatus');
            // switchFullScreen();

            if(type == 1){
                window.game.startRoaming();
                this.$emit('initNav')
            }
            if(type == 2){
                window.game.cameraControl.stopRoaming();
            }
        }
    }
}
</script>
<!-- 两种样式 768px为边界线-->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
*::-webkit-media-controls-start-playback-button {
  display: none ;
  -webkit-appearance: none;
}
*::-webkit-media-controls-panel {
  display: none ;
  -webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
  display: none ;
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  display: none;
}
* {
  padding: 0;
  margin: 0;
}
.loading-programs{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
}
.loading-programs .video-load{
    width: 100%;
    height: 100%;
    background:black;
}
.loading-programs > .choose-type{
    width: 100%;
    height: 100%;
    /* background-image: linear-gradient(120deg,#fccb90 0%,#d57eeb 100%); */

    display: flex;
    justify-content: center;
    align-items: center;
}
.loading-programs > .choose-type > div{
    width: 350px;
    height: 300px;
    opacity:1;

}

.loading-programs > .video-load > .video{
    width: 100%;
    height: 100%;
}
.loading-programs > .video-load > .video > video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.loading-programs > .video-load > .enter-page{
    width: 100%;
    height: 100%;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
#loader {
    display: block;
    width: 76px;
    height: 76px;
    margin: -38px 0 0 -38px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #9370DB;
    animation: spin 2s linear infinite;
}
#loader::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #BA55D3;
    animation: spin 3s linear infinite;

}
#loader::after {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #FF00FF;
    animation:spin 1.5s linear infinite;
}
@media screen and (max-width: 768px) {
    .loading-programs > .video-load > .loading-circle {
        position: fixed;
        width: 30%;
        bottom: 0px;
        left: 15%;
        bottom: 2%;
    }
    .loading-programs > .video-load > .loading-jump-to{
        position: fixed;
        top: 3%;
        right: 3%;
    }
    .loading-programs > .video-load > .loading-jump-to > div > img{
        width: 100px;
    }


.loading-programs > .choose-type > div > div{
    width: 100%;
    font-size: 22px;
    display: flex;
    margin-top: 20px;
    justify-content: space-around;
}
.loading-programs > .choose-type > div > div > div{
    width: 150px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
}
.loading-programs > .choose-type > div > div > div > img{
    width: 100px;
    height: 100px;
}


}
@media screen and (min-width: 768px) {
    .loading-programs > .video-load > .loading-circle {
        position: fixed;
        width: 30%;
        bottom: 0px;
        left: 5%;
        bottom: 2%;
    }
    .loading-programs > .video-load > .loading-jump-to{
        position: fixed;
        top: 3%;
        right: 3%;
    }
    .loading-programs > .video-load > .loading-jump-to > div > img{
        width: 150px;
    }


    .loading-programs > .choose-type > div > div{
        width: 100%;
        font-size: 22px;
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
    }
    .loading-programs > .choose-type > div > div > div{
        width: 150px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
    }
    .loading-programs > .choose-type > div > div > div > img{
        width: 150px;
        height: 150px;
    }
}
  .btn-shine {
      color: #fff;
      background: linear-gradient(to right , #4d4d4d 0 , #fff 10% , #4d4d4d 20%);
      background-position: 0;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: shine 3s linear infinite;
      font-weight: 600;
      font-size: 30px;
      text-decoration: none;
      white-space: nowrap;
  }

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
  @keyframes shine {
      0% {
          background-position: 0;
      }
      60% {
        background-position: 280px;
    }
    100% {
        background-position: 280px;
    }
  }
</style>
